<script>
import {defineComponent} from 'vue';
import appConfig from "../../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import ContainerCharts from "@/views/group/chart/Chart.vue";

export default defineComponent({
  name: "ContainerChart",
  page: {
    title: 'Group Chart',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    ContainerCharts
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Gráfico",
        href: "/",
      },
      {
        text: "Grupo",
        active: true,
      },
    ]
  }),

})
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <ContainerCharts/>
</template>

